<template>
    <div>
        <v-select
            :multiple="multipleSelect"
            :options="optionsSelect"
            :placeholder="'Selecciona ' + placeholderm"
            :loading="loadingData"
            v-model="selectedOption"
            @input="setCambio"
        ></v-select>
    </div>
</template>

<script>
export default {
    name: "selectMutable",
    props: {
        multipleSelect: {
            type: Boolean,
            required: true,
            default: false,
        },
        getDataOnMounted: {
            type: Boolean,
            required: true,
            default: false,
        },
        parametersData: {
            type: Array,
            required: false,
            default: () => [], // Devuelve un nuevo array en cada instancia,
        },
        placeholderm: {
            type: String,
            required: true,
            default: 'ingrese valor',
        },
        event: {
            type: String,
            required: true,
            default: 'ingrese valor',
        },
        urlData: {
            type: String,
            required: true,
            default: 'api end point',
        },
    },
    data() {
        // aquí podemos ejecutar código antes de declarar variables
        //const anioActual = new Date().getFullYear();
        return {
            // Inicializamos el año actual como el seleccionado por defecto
            selectedOption: null,
            // Creamos las opciones de años dinámicamente
            optionsSelect: [],
            loadingData: false,
        };
    },
    methods: {
        setCambio() {
            this.$emit('event' + this.$props.event, this.selectedOption);
        },
    },
    beforeMount() {
        //this.$emit('cambioAnio', this.selectedAnios);
    },
    async mounted() {
        if (this.$props.getDataOnMounted) {
            let $this = this
            this.loadingData = true
            await axios.post(this.$props.urlData,
                { data: this.$props.parametersData }
            ).then(({data}) => {
                $this.optionsSelect = data.data;
                }
            ).catch(error => {
                $this.$toastMessage('error',"Error en la petición, Código: " + error.toString(),8000,'top-right');
            }
            ).finally(() => {
                this.loadingData = false
            })
        }
    }
}
</script>

<style scoped>

</style>
