<template>
    <div>
        <vue-tabs active-tab-color="#c3a900"
                  active-text-color="white"
                  type="pills"
                  :start-index="1">

            <v-tab title="TIENDAS">
                <br>
                <tiendas-component></tiendas-component>
            </v-tab>

            <v-tab title="FORMATOS - CADENAS">
                <br>
                <cancadfmt-component></cancadfmt-component>
            </v-tab>

            <v-tab title="ESTADOS - CIUDADES">
                <br>
                <ciudades></ciudades>
            </v-tab>
            <v-tab title="IMPORTAR LAYOUT HERMES">
                <br>
                <layout-hermes></layout-hermes>
            </v-tab>
            <v-tab title="IMPORTAR SEGMENTACIÓN - SO">
                <br>
                <segmentacion></segmentacion>
            </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
import layoutHermes from './importLayout.vue'
import ciudades from './ciudades.vue'
import segmentacion from './importSegmentacion.vue'
export default {
    name: "admtiendas",
    components:{
        layoutHermes,
        ciudades,
        segmentacion,
    }
}
</script>

<style scoped>

</style>
