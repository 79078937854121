<template>
    <div>
        <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
        <div class="row">
            <div class="col-md-3">
                <filters-general ref="filtersReport"></filters-general>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-2">
                        <div v-if="!loadPreguntas" class="text-center"><br>
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                        <button class="btn ripple btn-success" v-if="vigentes && loadPreguntas" @click="togleTipo">Vigentes</button>
                        <button class="btn ripple btn-danger" v-if="!vigentes && loadPreguntas" @click="togleTipo">Vencidas</button>
                        <br>
                        <h3>{{fi}} - {{ff}}</h3>
                    </div>
                    <div class="col-md-8">
                        <label >Encuesta</label><br>
                        <select-encuesta :showExtras="false" :placeholderm="'Encuesta'" ref="encuestaFiltro"
                                         :getPasados="true"
                                         v-on:cargaEncuesta="showPreguntas" v-show="loadPreguntas"></select-encuesta>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button class="btn ripple btn-primary" @click="getData">Generar Reporte </button>
                        <br>
                        <div v-if="loadingGetData"><br>
                            <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                            <p>Preparando información, espere...</p>
                        </div>
                        <br>
                    </div><br>
                    <div v-show="showResult">
                        <div class="example">
                            <div class="border">
                                <div class="bg-gray-300 nav-bg">
                                    <nav class="nav nav-tabs">
                                        <a class="nav-link active" data-toggle="tab" href="#tabCont1">Gráfica <i class="fas fa-chart-pie"></i></a>
                                        <a class="nav-link" data-toggle="tab" href="#tabCont2">Galería <i class="fas fa-images"></i></a>
                                        <a class="nav-link" data-toggle="tab" href="#tabCont3">Tabla <i class="fas fa-table"></i></a>
                                        <a class="nav-link" data-toggle="tab" href="#tabCont4">Tiendas Reporte <i class="fas fa-store"></i></a>
                                    </nav>
                                </div>
                                <div class="card-body tab-content">
                                    <div class="tab-pane active show" id="tabCont1">
                                        <div class="text-center">
                                            <highcharts :options="chartOptions"></highcharts>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tabCont2">
                                        <!-- Modal effects -->
                                        <div class="modal" id="modalShowData" data-backdrop="static">
                                            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                <div class="modal-content modal-content-demo">
                                                    <div class="modal-header">
                                                        <h6 class="modal-title">Detalle Imagen <b>Encuestas</b> <i class="fas fa-file-image"></i></h6>
                                                        <button ref="closeModal" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="row" v-if="dataGaleria.length>0">
                                                            <div class="col-md-6">
                                                                <div class="text-center">
                                                                    zoomed: {{ zoomed }}
                                                                    <button @click="$refs.zoomer.zoomIn()">+</button>
                                                                    <button @click="$refs.zoomer.zoomOut()">-</button>
                                                                </div>
                                                                <v-zoomer ref="zoomer"
                                                                          style=" border: solid 1px silver;width: 350px; height: 500px;"
                                                                          :max-scale="10"
                                                                          :zooming-elastic="false"
                                                                          :zoomed.sync="zoomed">
                                                                    <img
                                                                        :src="'/storage'+dataGaleria[idxGlobalSelected].Arch"
                                                                        style="object-fit: contain; width: 100%; height: 100%;"
                                                                        alt="imgZoom"
                                                                    >
                                                                </v-zoomer>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label class="tx-bold">Código</label>
                                                                <p class="tx-bold">{{dataGaleria[idxGlobalSelected].Cdgentpto}}</p>
                                                                <label class="tx-bold">Producto</label>
                                                                <p class="tx-12">{{dataGaleria[idxGlobalSelected].Producto}}</p>
                                                                <label class="tx-bold">Fecha Registro</label>
                                                                <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Fchcrt}}</p>
                                                                <label class="tx-bold">Canal</label>
                                                                <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Canal}}</p>
                                                                <label class="tx-bold">Cadena</label>
                                                                <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Cadena}}</p>
                                                                <label class="tx-bold">Formato</label>
                                                                <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Formato}}</p>
                                                                <label class="tx-bold">Tienda</label>
                                                                <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Tienda}}</p>
                                                                <label class="tx-bold">Estado</label>
                                                                <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Estado}}</p>
                                                                <label class="tx-bold">Ciudad</label>
                                                                <p class="tx-12 tx-bold text-danger">{{dataGaleria[idxGlobalSelected].Ciudad}}</p>
                                                                <label class="tx-bold">Usuario</label>
                                                                <p class="tx-12 tx-bold text-info" v-html="dataGaleria[idxGlobalSelected].Usuario"></p>
                                                                <p class="tx-12 tx-bold text-danger" v-if="dataGaleria[idxGlobalSelected].Comentario!=='' && dataGaleria[idxGlobalSelected].Comentario!=='EJECUCIÓN INCORRECTA'">Cnt: {{dataGaleria[idxGlobalSelected].Comentario}}</p>
                                                                <p class="badge badge-danger" v-else>{{dataGaleria[idxGlobalSelected].Comentario}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Modal effects-->
                                        <div class="row">
                                            <div class="col-md-2"><h5><b class="text-success">ENCUESTAS</b></h5></div>
                                            <div class="col-md-4"><h5>Total Imagenes: <b class="text-success">{{totalImg}}</b></h5></div>
                                            <div class="col-md-6">
                                                <button v-show="selectAllBtn" class="btn ripple btn-success" @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Seleccionar Todo</button> &nbsp;&nbsp;&nbsp;
                                                <button v-show="!selectAllBtn" class="btn ripple btn-danger " @click="selectAll(selectAllBtn)"><i class="fe fe-check-square"></i> Deseleccionar Todo</button> &nbsp;&nbsp;&nbsp;
                                                <button v-if="!loadfileZip" class="btn ripple btn-primary " @click="ExportFile" ><i class="fe fe-download-cloud"></i> Descargar Galeria</button> &nbsp;&nbsp;&nbsp;
                                                <button v-if="!loadFilePptx" class="btn ripple btn-secondary "  @click="ExportFilePptx"><i class="fas fa-file-powerpoint"></i> Descargar Power Point</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4"></div>
                                            <div class="col-md-4">
                                                <div v-if="loadfileZip || loadFilePptx">
                                                    <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                                    <p>Preparando archivo, espere...</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4"></div>
                                        </div>
                                        <br>
                                        <div id="global" class="row" v-if="dataGaleria.length>0">
                                            <div class="col-md-4" v-for="items in dataGaleria" :class="{'border border-danger'  : items.Comentario==='EJECUCIÓN INCORRECTA' }">
                                                <div class="row" v-if="items.ShowT">
                                                    <div class="col-md-1">
                                                        <label class="ckbox" v-if="items.Disponible === '1'">
                                                            <input type="checkbox" v-model="items.Checked">
                                                            <span> </span>
                                                        </label><br>
                                                        <span class="fas fa-trash" style="color: red;cursor: pointer"  @click="deleteImageGaleria(items.Id)" v-if="false"></span><br>
                                                        <span class="fas fa-edit" style="color: darkblue;cursor: pointer" v-if="items.Comentario!=='EJECUCIÓN INCORRECTA' && enableEdit" @click="deleteImage(items.Id)"></span>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <img v-show="items.Mostrar === '1'" class="img-thumbnail" :src="'/storage'+items.Arch" alt="imagenEncuestas" @load="onImgLoad(items.Id)" @error="setFail(items.Id)"/>
                                                        <img v-if="items.Mostrar === '0'" alt="loading img" class="img-thumbnail" src="/theme/img/loads/lavaload.gif"/>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <p class="tx-bold">{{items.Kcmid}}
                                                        <p class="tx-12 tx-bold text-danger">{{items.Fchreg}}</p>
                                                        <p class="tx-12 tx-bold text-danger" v-if="items.Comentario!=='' && items.Comentario!=='EJECUCIÓN INCORRECTA'">Cnt: {{items.Comentario}}</p>
                                                        <p class="badge badge-danger" v-else>{{items.Comentario}}</p>
                                                        <p class="badge badge-info" v-if="items.Respuesta ==='SI'">{{items.Respuesta}}</p>
                                                        <p class="badge badge-danger" v-else>{{items.Respuesta}}</p>
                                                        <button @click="loadData(items.Id)" v-if="items.Disponible === '1'" class="btn ripple btn-outline-info" data-effect="effect-super-scaled" data-toggle="modal" href="#modalShowData"><i class="fas fa-eye"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="text-center">
                                                    <button class="btn ripple btn-info btn-with-icon" @click="mostrarveintemas"><i class="fas fa-question"></i> Mostrar siguientes 20</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tabCont3">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="text-center">
                                                            <button class="btn ripple btn-success btn-with-icon" @click="getReportDataRespuestas"><i class="fas fa-file-excel"></i> Descargar Excel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <v-client-table :data="tableDataRespuestas" :columns="columnsDataRespuestas" :options="optionsDataRespuestas" class="table-responsive" ref="tableEncuestas">
                                                </v-client-table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tabCont4">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="text-center">
                                                        <button class="btn ripple btn-success btn-with-icon" @click="getReportDataRespuestasFiltro"><i class="fas fa-file-excel"></i> Descargar Excel</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-client-table :data="tableDataRespuestasFil" :columns="columnsDataRespuestasFil" :options="optionsDataRespuestasFil" class="table-responsive" ref="tableEncuestasFil">
                                                <div slot="Contestada" slot-scope="props">
                                                    <span v-html="props.row.Contestada"></span>
                                                </div>
                                            </v-client-table>
                                            <br><br>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="text-center">
                                                        <button class="btn ripple btn-success btn-with-icon text-center" @click="getReportDataRespuestasFiltroDos"><i class="fas fa-file-excel"></i> Descargar Excel</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="text-center">
                                                        <button class="btn ripple btn-info btn-with-icon text-center" data-effect="effect-just-me" data-toggle="modal" href="#myModal4" v-if="deleteAddTienda"><i class="fas fa-plus"></i> Agregar Tienda</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="text-center">
                                                        <button class="btn ripple btn-success btn-with-icon text-center" data-effect="effect-just-me" data-toggle="modal" href="#myModalTiendas" v-if="validaUsuarioAdmin || validaUsuario"><i class="fas fa-file-excel-o"></i> Agregar Tienda Layout</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-client-table :data="tableDataRespuestasFilDos" :columns="columnsDataRespuestasFilDos" :options="optionsDataRespuestasFilDos" class="table-responsive" ref="tableEncuestasFil">
                                                <div slot="Contestada" slot-scope="props">
                                                    <span v-html="props.row.Contestada"></span>
                                                </div>
                                                <div slot="Kcmid" slot-scope="props" class="text-center">
                                                    <b>{{props.row.Kcmid}}</b><br>
                                                    <h6 v-if="deleteAddTienda = validaUsuario"><i class="fa fa-trash-alt" style="color: red;cursor:pointer;" @click="elimiarTienda(props.row.Cveenttda)"></i></h6>
                                                </div>
                                            </v-client-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="myModal4" tabindex="-1" :class="{'show':shoModal}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Agregar tienda</h6><button id="closeedit" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="shoModal = !shoModal" ref="cerrarModal"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-8">
                                <tiendasmain-component :placeholderm="'Tienda'" ref="selectNewTienda"></tiendasmain-component>
                                <div class="text-center">
                                    <div v-if="loadingGetData"><br>
                                        <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                        <p>Preparando información, espere...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success"  @click="saveCambio">Agregar <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="myModalTiendas" tabindex="-1" :class="{'show':shoModal}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Agregar tiendas desde layout</h6><button id="closeedit" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="shoModal = !shoModal" ref="cerrarModal"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-8">

                                <div class="text-center">
                                    <div v-if="loadingGetData"><br>
                                        <img :src="'/theme/img/loads/loadfile.gif'" alt="carga archivo" height="40">
                                        <p>Preparando información, espere...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <p-check class="p-default p-curve" color="success" off-color="danger" toggle style="font-size: 20px" @change="type = !type">
                                    AGREGAR TIENDAS
                                    <label slot="off-label">ELIMINAR TIENDAS</label>
                                </p-check>
                            </div>
                            <div class="col-md-4"></div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <label>Archivo a procesar
                                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                                </label>
                                <br><br><br>
                                <button v-if="!showLoad" @click="submitFile()" class="btn ripple btn-with-icon btn-primary">Procesar archivo &nbsp;&nbsp;<i class="fas fa-cogs"></i></button><br>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar <i class="fa fa-times"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from "highcharts";
function createChartRespuestas(vm){
    return{
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Resultado Preguntas'
        },
        tooltip: {
            pointFormat: '{point.nombre}: <b>{point.y:.2f}</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.2f} % <br><b style="color: darkorange">{point.y}</b>',
                    style: {
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                    }
                }
            }
        },
        credits:{
            enabled:true, verticalAlign: "top", text: 'GRUPO PROMOCIONES', href: 'https://grupopromociones.com'
        },
        series: [{
            name: 'Respuestas',
            colorByPoint: true,
            data: []
        }]
    }
}
export default {
    props:{
        idUsers:{type:Number,required:true,default:0}
    },
    computed:{
        validaUsuario(){
            return this.users.includes(this.idUsers);
        },
        validaUsuarioAdmin(){
            return this.usersAdmins.includes(this.idUsers);
        }
    },
    name: "reporte",
    data(){
        return{
            file: '',
            showLoad:false,
            type:false,
            users:[31301,30331,32182,32936,32104,21025,20100,31999,32796,21027,21018,30109,30184,21016,32231,20069,32660,30001,20115,30199,32823,22005,22004,30012,20042,30894,32384,30235,2,33440,21015,30215,30247,31983,30363,32634,33063,33013,32998,33395,33438,30241,33782,33889,33890,33917,33873,34437,34642, 35973],
            usersAdmins:[34437,32182,2,30001,34642, 35973],
            enableEdit:false,
            deleteAddTienda:false,
            shoModal:false,
            vigentes:true,
            loadPreguntas:false,
            loadingGetData:false,
            showResult:false,
            chartOptions: createChartRespuestas(this),
            selectAllBtn:true,
            isLoaded: false,
            totalImg:0,
            imgToZoom:'/theme/img/impg.png',
            idxGlobalSelected:0,
            loadFilePptx:false,
            loadfileZip:false,
            dataGaleria:[],
            zoomed:false,
            tipoGaleria:'ENCUESTAS',
            columnsDataRespuestas: ['Id','Fecha','Pregunta','Agencia','Area','Estado','Ciudad','Gestor','Distrito','Rol','Canal','Cadena','Formato','Tienda','Kcmid','Respuesta','Comentario'],
            tableDataRespuestas: [],
            optionsDataRespuestas: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Kcmid','Tienda','Area','Formato','Canal','Cadena','Estado','Ciudad','Gestor','Distrito'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                    InventarioF: [
                        {
                            class:'bg-danger',
                            condition: row => row.InventarioF <= 0
                        }
                    ]
                },
                columnsDropdown:false,
                headings: {

                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Producto: 'text-center',
                    Gestor: 'text-center',
                    Tienda: 'text-center',
                    Area: 'text-center',
                    Formato: 'text-center',
                    Canal: 'text-center',
                    Cadena: 'text-center',
                    Fecha: 'text-center',
                    Fotografico: 'text-center',
                    Ventas: 'text-center',
                    Inventarios: 'text-center',
                    Comentario: 'text-center',
                    Encuestas: 'text-center',
                    Faltantes: 'text-center',
                    Exhibiciones: 'text-center',
                    Anaquel: 'text-center',
                    Promociones: 'text-center',
                    Comentarios: 'text-center',
                    Resurtidos: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsDataRespuestasFil: ['Tienda','Kcmid','Area','Canal','Formato','Estado','Ciudad','Contestada','Programada','Pregunta'],
            tableDataRespuestasFil: [],
            optionsDataRespuestasFil: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Kcmid','Area','Formato','Canal'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                    InventarioF: [
                        {
                            class:'bg-danger',
                            condition: row => row.InventarioF <= 0
                        }
                    ]
                },
                columnsDropdown:false,
                headings: {

                },
                columnsClasses: {
                    Contestada: 'text-center',
                    Programada: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            columnsDataRespuestasFilDos: ['Tienda','Area','Canal','Cadena','Formato','Estado','Ciudad','Kcmid','Contestada','Programada','Pregunta'],
            tableDataRespuestasFilDos: [],
            optionsDataRespuestasFilDos: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [5,10],
                filterable: ['Kcmid','Area','Formato','Canal'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                cellClasses:{
                    InventarioF: [
                        {
                            class:'bg-danger',
                            condition: row => row.InventarioF <= 0
                        }
                    ]
                },
                columnsDropdown:false,
                headings: {

                },
                columnsClasses: {
                    Contestada: 'text-center',
                    Programada: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            fi:'',
            ff:'',
            imagesS:40
        }
    },
    mounted() {
        this.$refs.filtersReport.shows.departamentos = false;
        this.$refs.filtersReport.shows.categorias = false;
        this.$refs.filtersReport.shows.fabricantes = false;
        this.$refs.filtersReport.shows.marcas = false;
        this.$refs.filtersReport.shows.productos = false;
        this.$refs.filtersReport.shows.trades = false;
        this.$refs.selectNewTienda.getTiendas();
        const found = this.users.find(element => element === this.idUsers);
        //console.log(found)
        if (found !== undefined){
            this.enableEdit = true;
        }
    },
    methods:{
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
        async submitFile() {
            let $this = this;
            if (this.file === '') {
                $this.$toastMessage('error', 'Seleccione un archivo para procesar', 4000, 'center');
            } else {
                var enct = this.$refs.encuestaFiltro.selectedEncuestas;
                this.showLoad = true;
                this.loadingGetData = true;
                let formData = new FormData();
                /*
                    Add the form data we need to submit
                */
                formData.append('file', this.file);
                formData.append('combo', 42);
                formData.append('type', this.type);
                formData.append('enct', enct.value);
                /*
                  Make the request to the POST /single-file URL
                */
                await axios.post('/api/Encuestas/Peticiones',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function (r) {
                    if (r.data.Success) {
                        $this.$swal.fire({
                            icon: 'success',
                            title: 'ÉXITO',
                            html: $this.type ? '<span style="color: darkred">Errores: ' + r.data.errores + '</span> <br> <span style="color: darkgreen">Procesados: ' + r.data.ok + '</span> <br> <span style="color: darkgreen">Existentes: ' + r.data.existentes + '</span>' : '<span style="color: darkred">Errores: ' + r.data.errores + '</span> <br> <span style="color: darkgreen">Procesados: ' + r.data.ok + '</span> ',
                            footer: '<b>' + r.data.Mensaje + '</b>'
                        }).then((result)=>{
                            if (result.value){
                                $this.file = '';
                                $this.showLoad = false;
                                $this.$refs.file.value = null;
                                $this.loadingGetData = false;
                                $this.getData();
                            }
                        });

                    } else {
                        $this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Archivo procesado con errores!',
                            footer: '<b>' + r.data.Mensaje + '</b>'
                        }).then((result)=>{
                            if (result.value){
                                $this.file = '';
                                $this.showLoad = false;
                                $this.$refs.file.value = null;
                                $this.loadingGetData = false;
                                $this.getData();
                            }
                        });
                    }

                })
                    .catch(function (e) {
                        $this.$toastMessage('error', 'Error no definido, reportar a IT: ' + e, 4000, 'center');
                        $this.file = '';
                        $this.showLoad = false;
                        $this.$refs.file.value = null;
                        $this.loadingGetData = false;
                    });
            }

        },
        deleteImageGaleria(cve){
            this.$swal.fire({
                title: '¿Eliminar Imagen?',
                text: 'Al aceptar, la información se eliminara permanentemente',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var resp = await this.$peticionesApi('/api/ReporteGrafico/deleteImage', {galeria: 'encuestas', cve: cve});
                    if (resp !== null){
                        this.$swal.fire('Exito!', 'Acción realizada satisfactoriamente.', 'success');
                        this.getData();
                    }
                }
            });
        },
        async deleteImage(cve) {
            let idx = this.dataGaleria.findIndex(element => element.Id === cve);
            this.$swal.fire({
                title: '¿Cambiar Respuesta?',
                text: 'Al aceptar, la respuesta cambia a NO - EJECUCIÓN INCORRECTA',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var resp = await this.$peticionesApi('/api/ReporteGrafico/changeRespuestaEncuesta', {galeria: 'encuestas', cve: cve});
                    if (resp !== null){
                        this.$swal.fire('Exito!', 'Acción realizada satisfactoriamente.', 'success');
                        this.dataGaleria[idx].Comentario = 'EJECUCIÓN INCORRECTA';
                    }
                }
            });
        },
        saveCambio(){
            let $this = this;
            var enct = this.$refs.encuestaFiltro.selectedEncuestas;
            if (enct === null){
                this.$toastMessage('error',"Seleccione una encuesta por favor!",4000,'center');
            }else {
                var tiendas = this.$refs.selectNewTienda.selectedTiendas;
                if (tiendas === null) {
                    this.$toastMessage('error', "Seleccione una tienda para agregar!", 4000, 'center');
                } else {
                    $this.loadingGetData = true;
                    axios.post('/api/Encuestas/Peticiones', {kcmid: tiendas.value, encuesta: enct.value, combo: 37})
                        .then(r => {
                                $this.loadingGetData = false;
                                if (r.data.Success){
                                    $this.$swal.fire(r.data.Mensaje, '', 'success');
                                }else{
                                    $this.$swal.fire(r.data.Mensaje, '', 'error');
                                }

                            },
                            error => {
                                $this.$toastMessage('error', 'Error: ' + error + ' Por favor envia este error al área de soporte!', 9000, 'center');
                                $this.loadingGetData = false;
                            }
                        );
                    this.$refs.cerrarModal.click();
                }
            }
        },
        elimiarTienda(id){
            var enct = this.$refs.encuestaFiltro.selectedEncuestas;
            if (enct === null){
                this.$toastMessage('error',"Seleccione una encuesta por favor!",4000,'center');
            }else{
                this.$swal.fire({
                    title: '¿Eliminar tienda?',
                    text:'Esto, eliminar las respuestas y fotos, registradas anteriormente!',
                    showCancelButton: true,
                    confirmButtonText: 'Eliminar',
                    confirmButtonColor:'#1ba631',
                    cancelButtonColor:'#b90c17',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        let $this=this;$this.loadingGetData = true;
                        axios.post('/api/Encuestas/Peticiones',{kcmid:id,encuesta:enct.value,combo:36})
                            .then(r => {
                                    $this.loadingGetData = false;
                                    $this.$swal.fire(r.data.Mensaje,'','success');
                                },
                                error => {
                                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                                    $this.loadingGetData = false;
                                }
                            );
                    } else {
                        //this.$swal.fire('Changes are not saved', '', 'info')
                    }
                })
            }

        },
        mostrarveintemas(){ let $this = this;
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (a<=$this.imagesS){
                    $this.dataGaleria[z].ShowT = true;
                }
                a++;
            }
            this.imagesS = this.imagesS + 20;
        },
        findIdx(cve){
            var a = 0;
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                if (this.dataGaleria[z].Id === cve){
                    a = z;
                }
            }
            return a;
        },
        loadData(cve){
            this.idxGlobalSelected = this.findIdx(cve);
        },
        keyboardEvent (e) {
            if (e.which === 13) {
                this.getData();
            }
        },
        getData(){ let $this = this;
            var filters = this.$refs.filtersReport.filters;
            var enct = this.$refs.encuestaFiltro.selectedEncuestas;
            if (filters.timeInit === null){
                this.$toastMessage('error',"Fecha Inicio Es Obligatoria",4000,'center');
            }else if (filters.timeEnd === null){
                this.$toastMessage('error',"Fecha Termino Es Obligatoria",4000,'center');
            }
            else if (enct === null){
                this.$toastMessage('error',"Seleccione una encuesta por favor!",4000,'center');
            }else if (Date.parse(filters.timeEnd) < Date.parse(filters.timeInit)){
                this.$toastMessage('error',"Seleccione una fecha fin mayor a la fecha inicial!",4000,'center');
            }else{
                this.loadingGetData = true;
                this.showData = false;
                this.showResult = false;
                this.chartOptions.series[0].data = [];
                this.tableDataRespuestas = [];
                this.tableDataRespuestasFil = [];
                this.tableDataRespuestasFilDos = [];
                this.dataGaleria = [];
                axios.post('/api/Encuestas/Estadisticas',{data:filters,encuesta:enct.value})
                    .then(r => {
                        $this.loadingGetData = false;
                        $this.showData = true;
                        $this.showResult = true;
                        $this.dataGaleria = r.data.DataImg;
                        $this.totalImg = r.data.TotalFotos;
                        $this.tableDataRespuestas = r.data.TablaRespuestas;
                        $this.tableDataRespuestasFil = r.data.TablaFiltro;
                        $this.tableDataRespuestasFilDos = r.data.TablaFiltroDos;
                        $this.chartOptions.series[0].data = r.data.Grafica;
                        $this.fi = r.data.FechaI;
                        $this.ff = r.data.FechaF;
                        $this.$toastMessage('success',r.data.Mensaje,9000,'center');
                        var a = 0;
                        for (var z = 0; z < this.dataGaleria.length;z++)
                        {
                            if (a<=20){
                                $this.dataGaleria[z].ShowT = true;
                            }else{
                                $this.dataGaleria[z].ShowT = false;
                            }
                           a++;
                        }
                    },
                    error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        $this.loadingGetData = false;
                    }
                );
            }
        },
        togleTipo(){
            this.vigentes = !this.vigentes;
            this. loadPreguntas=false;
            this.$refs.encuestaFiltro.tipoConsulta = this.vigentes;
            this.$refs.encuestaFiltro.getEncuestas();
        },
        showPreguntas(){
            this.loadPreguntas=true;
        },
        selectAll(type){
            for (var z = 0; z < this.dataGaleria.length;z++)
            {
                this.dataGaleria[z].Checked = type;
            }
            this.selectAllBtn = !this.selectAllBtn;
        },
        setFail(cve) {
            var idx = this.findIdx(cve);
            this.dataGaleria[idx].Mostrar = '1';
            this.dataGaleria[idx].Arch = '/theme/img/img.png';
            this.dataGaleria[idx].Disponible = '0';
        },
        onImgLoad(cve) {
            var idx = this.findIdx(cve);
            this.dataGaleria[idx].Mostrar = '1';
        },
        ExportFile(){let $this = this;
            this.loadfileZip = true;
            this.$swal({
                title: "Espere",
                text: "Preparando zip " + this.tipoGaleria + " ATENEA México, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/Zip',
                responseType: 'arraybuffer',
                data: {data:$this.dataGaleria,galeria:this.tipoGaleria}
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/zip' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadfileZip = false;
                    $this.$toastMessage('success','Zip descargado!',3000,'center');
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
        },
        ExportFilePptx(){let $this = this;
            this.loadFilePptx = true;
            this.$swal({
                title: "Espere",
                text: "Preparando presentación " + this.tipoGaleria + " ATENEA México, por favor espere.",
                imageUrl: "/theme/img/loads/lavaload.gif",
                showConfirmButton: false
            });
            return axios({
                method: 'post',
                url: '/api/Download/Pptx',
                responseType: 'arraybuffer',
                data: {data:$this.dataGaleria,galeria:this.tipoGaleria}
            }).then(r => {
                    let fileName = r.headers["content-disposition"].split("filename=")[1];
                    let blob = new Blob([r.data], { type: 'application/pptx' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    $this.$swal.close();
                    $this.loadFilePptx = false;
                    $this.$toastMessage('success','Presentación descargada!',3000,'center');
                },
                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
            );
        },
        getReportDataRespuestas(){ let $this = this;
            this.loadingGetData = true;
            var dataI = this.tableDataRespuestas;
            axios({
                method: 'post',
                url: '/api/Encuestas/exportLayoutRespuestas',
                responseType: 'arraybuffer',
                data: {data: dataI}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ReporteEncuestasRespuestas.xlsx'
                    link.click()
                    $this.loadingGetData=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadingGetData=false;
                }
            );
        },
        getReportDataRespuestasFiltro(){ let $this = this;
            this.loadingGetData = true;
            var dataI = this.tableDataRespuestasFil;
            axios({
                method: 'post',
                url: '/api/Encuestas/exportLayoutRespuestasFilt',
                responseType: 'arraybuffer',
                data: {data: dataI}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ReporteEncuestasRespuestasContestadas.xlsx'
                    link.click()
                    $this.loadingGetData=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadingGetData=false;
                }
            );
        },
        getReportDataRespuestasFiltroDos(){ let $this = this;
            this.loadingGetData = true;
            var dataI = this.tableDataRespuestasFilDos;
            axios({
                method: 'post',
                url: '/api/Encuestas/exportLayoutRespuestasFiltDos',
                responseType: 'arraybuffer',
                data: {data: dataI}}).then(r => {
                    let blob = new Blob([r.data], { type: 'application/xlsx' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ReporteEncuestasTiendasCargadas.xlsx'
                    link.click()
                    $this.loadingGetData=false;
                },
                error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    $this.loadingGetData=false;
                }
            );
        },
    },
}
</script>

<style scoped>

</style>
