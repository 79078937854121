<script>
import lideres from './components/lideresReport.vue'
export default {
    name: "lideresReporte",
    components: {
        lideres
    }
}
</script>

<template>
    <div>
        <lideres></lideres>
    </div>
</template>

<style scoped>

</style>
