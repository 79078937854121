<script>
import selectLideres from './../../../components/generales/selectMutable.vue'
import selectCadena from './../../../components/generales/selectMutable.vue'
import selectCategoria from './../../../components/generales/selectMutable.vue'
export default {
    name: "lideresReport",
    components: {
        selectLideres,
        selectCadena,
        selectCategoria,
    },
    data(){
        return {
            semanas: 5,
            newObjetivo: {
                objetivo: 0,
                lider: 0,
                categoria: 0,
                cadena: 0,
                anio: 0,
            },
            lideres: [],
            cadenas: [],
            categorias: [],
            showButtonSave: true,
            showLoading: false,
            showFilters: true,
            timeInit: null,
            dataReport: [],
            semanasTable: []
        }
    },
    computed: {
        porcentajeCalculo() {
            if (this.lider.Data.length > 1) {
                const ultimaSemana = this.lider.Data[this.lider.Data.length - 1];
                const penultimaSemana = this.lider.Data[this.lider.Data.length - 2];
                if (penultimaSemana.Total !== 0) {
                    return (
                        ((ultimaSemana.Total - penultimaSemana.Total) / penultimaSemana.Total) * 100
                    ).toFixed(2);
                }
            }
            return 'N/A';
        }
    },
    methods:{
        calcularClase(valor){
            if (valor <= 15){
                return 'fw-bold bg-danger text-white'
            }
            else if (valor > 15 && valor < 70){
                return 'fw-bold bg-warning text-white'
            }
            else {
                return 'fw-bold bg-success text-white'
            }
        },
        setValue(lider){
            this.lideres = lider != null ? lider : []
        },
        setValueCadena(cadenas){
            this.cadenas = cadenas != null ? cadenas : []
        },
        setValueCategoria(categoria){
            this.categorias = categoria != null ? categoria : []
        },

        abrirModal(categoria, lider, cadena, anio) {
            // Muestra el modal usando referencia al componente Modal
            this.newObjetivo.categoria = categoria;
            this.newObjetivo.lider = lider;
            this.newObjetivo.cadena = cadena;
            this.newObjetivo.anio = anio;
            $(this.$refs.miModal).modal("show");
        },
        cerrarModal() {
            // Oculta el modal usando referencia al componente Modal
            $(this.$refs.miModal).modal("hide");
        },
        async saveObjetivo() {
            let $this = this
            this.showButtonSave = false
            await axios.post('/api/Exhibiciones/saveObjetivoLiderCadenaCategoria',
                {
                    data: this.newObjetivo
                }
            ).then(async ({data}) => {
                    $this.$toastMessage('success',"Objetivo, registrado correctamente!",8000,'top-right');
                    await $this.getReport()
                }
            ).catch(error => {
                    $this.$toastMessage('error',"Error en la petición, Código: " + error.toString(),8000,'top-right');
                }
            ).finally(() => {
                $(this.$refs.miModal).modal("hide");
                $this.showButtonSave = true
            })
        },
        async getReport(){
            if (this.lideres.length === 0){
                this.$toastMessage('error',"Seleccione al menos un lider",4000,'center')
            }
            else if (this.cadenas.length === 0){
                this.$toastMessage('error',"Seleccione al menos una cadena",4000,'center')
            }
            else if (this.categorias.length === 0){
                this.$toastMessage('error',"Seleccione al menos una categoria",4000,'center')
            }
            else if (this.timeInit === null){
                this.$toastMessage('error',"Seleccione la fecha de inicio del reporte",4000,'center')
            }
            else {
                let $this = this
                this.showLoading = true
                this.dataReport = []
                this.semanasTable = []
                await axios.post('/api/Exhibiciones/getLideresReport',
                    {
                        lideres: this.lideres,
                        semanas: this.semanas,
                        cadenas: this.cadenas,
                        fecha: this.timeInit,
                        categorias: this.categorias,
                    }
                ).then(({data}) => {
                        $this.dataReport = data.data;
                        $this.semanasTable = data.semanas;
                    }
                ).catch(error => {
                        $this.$toastMessage('error',"Error en la petición, Código: " + error.toString(),8000,'top-right');
                    }
                ).finally(() => {
                    this.showLoading = false
                })
            }
        }
    }
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-4" v-show="showFilters">
                <select-lideres
                    :event="'SelectLider'"
                    :get-data-on-mounted="true"
                    :multiple-select="true"
                    :placeholderm="'Seleccione lideres'"
                    :url-data="'/api/AdminCatalogos/getLideresRegiones'"
                    @eventSelectLider="setValue"
                ></select-lideres>
                <br>
                <select-cadena
                    :event="'SelectCadena'"
                    :get-data-on-mounted="true"
                    :multiple-select="true"
                    :placeholderm="'Seleccione cadena'"
                    :url-data="'/api/AdminCatalogos/getCadenasSelect'"
                    @eventSelectCadena="setValueCadena"
                ></select-cadena>
                <br>
                <select-categoria
                    :event="'SelectCategoria'"
                    :get-data-on-mounted="true"
                    :multiple-select="true"
                    :placeholderm="'Seleccione categoria'"
                    :url-data="'/api/AdminCatalogos/getCategoriasSelect'"
                    @eventSelectCategoria="setValueCategoria"
                ></select-categoria>
                <br>
                <div class="text-center">
                    <p>Selecciona fecha de inicio </p>
                    <date-picker
                        v-model="timeInit"
                        valueType="YYYY-MM-DD"
                        type="week"
                    ></date-picker>
                    <br>
                    <small class="text-info tx-bold">{{ timeInit }}</small>
                    <p>Selecciona las semanas a vizualizar </p>
                    <p-radio class="p-icon p-round p-bigger p-pulse" name="icon_solid" color="primary" :value="5" v-model="semanas">
                        <i slot="extra" class="icon mdi mdi-check"></i>
                        5
                    </p-radio>

                    <p-radio class="p-icon p-round p-bigger p-pulse" name="icon_solid" color="success" :value="10" v-model="semanas">
                        <i slot="extra" class="icon mdi mdi-check"></i>
                        10
                    </p-radio>

                    <p-radio class="p-icon p-round p-bigger p-pulse" name="icon_solid" color="info" :value="15" v-model="semanas">
                        <i slot="extra" class="icon mdi mdi-check"></i>
                        15
                    </p-radio>
                </div>
                <br>
                <div class="text-center">
                    <button class="btn btn-primary btn-rounded" @click="getReport">Generar reporte</button>
                </div>
            </div>
            <div :class="{'col-md-8' : showFilters, 'col-md-12' : !showFilters}">
                <!-- Validar si hay datos en dataReport -->
                <div class="text-left">
                    <button class="btn  btn-rounded" :class="{'btn-danger': showFilters, 'btn-info': !showFilters}" @click="showFilters = !showFilters">
                        <i :class="{'fas fa-arrow-left': showFilters, 'fa fa-arrow-right': !showFilters}"></i>
                    </button>
                </div>
                <div v-if="dataReport && dataReport.length > 0">
                    <div class="row">
                        <!-- Card dinámica por cada elemento en dataReport -->
                        <div
                            class="col-md-12 mb-3"
                            v-for="(item, index) in dataReport"
                            :key="index"
                        >
                            <!-- Card de Bootstrap 4 -->
                            <div class="card">
                                <div class="card-header">
                                    <!-- Título dinámico de la categoría -->
                                    <b class="tx-bold h3">{{ item.Categoria.label }}</b>
                                </div>
                                <div class="card-body">
                                    <!-- Tabla dinámica en el body -->
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead class="thead-light">
                                            <tr>
                                                <th class="text-center">Líder</th>
                                                <th class="text-center">Nombre</th>
                                                <th class="text-center">Cadena</th>
                                                <!-- Generar dinámicamente los headers de las semanas -->
                                                <th
                                                    v-for="(value, key) in semanasTable"
                                                    :key="'semana-header-' + key"
                                                    class="text-center"
                                                >
                                                    {{ value.YearReal }} <br>
                                                    {{ value.Week }} <br>

                                                </th>
                                                <th class="text-center">Objetivo</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Contenido dinámico de la tabla -->
                                            <template v-for="(lider, liderIndex) in item.Categoria.Lideres">
                                                <tr  :class="{'table-info' : lider.Cadena === 'Total'}">
                                                    <td v-if="liderIndex % item.Total === 0" :rowspan="item.Total" class="align-middle tx-bold text-center">
                                                        {{ lider.Lider }}
                                                    </td>
                                                    <td v-if="liderIndex % item.Total === 0" :rowspan="item.Total" class="align-middle tx-bold text-center">
                                                        {{ lider.Nombre }}
                                                    </td>
                                                    <td>{{ lider.Cadena }}</td>
                                                    <!-- Generar dinámicamente los valores de las semanas -->
                                                    <template v-for="(value, key) in lider.Data">
                                                        <td
                                                            v-if="value.Anio !== 'N'"
                                                            class="text-center"
                                                        >
                                                            {{ value.Total }}
                                                            <i
                                                                v-if="value.hasOwnProperty('Comparacion')"
                                                                :class="{'fe fe-trending-down text-danger': value.Comparacion === 'Menor', 'fe fe-trending-up text-success': value.Comparacion === 'Mayor'}"
                                                            ></i>
                                                            <br v-if="value.Objetivo > 0 && value.Total >= value.Objetivo">
                                                            <i v-if="value.Objetivo > 0 && value.Total >= value.Objetivo" class="fe fe-star text-primary"></i>
                                                        </td>
                                                        <td v-else class="text-center align-middle" :class="calcularClase(value.Total)">
                                                            {{ value.Total }}%
                                                        </td>
                                                    </template>
                                                    <td class="text-center align-middle">
                                                        <div v-if="lider.Objetivo === 0">
                                                            <span @click="abrirModal(lider.CategoriaId, lider.LiderId, lider.CadenaId, lider.Anio)" style="cursor: pointer">
                                                                {{ lider.Objetivo }}
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            <b>{{ lider.Objetivo }}</b>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <!-- Pie de la card si se necesita -->
                                    <small class="text-muted">Generado dinámicamente</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Mostrar mensaje si no hay datos -->
                <div v-else>
                    <p class="text-danger tx-bold">No hay datos disponibles.</p>
                </div>

            </div>
        </div>
        <!-- Modal -->
        <!-- Referencia para manejar el modal -->
        <div
            class="modal fade"
            id="miModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="miModalLabel"
            aria-hidden="true"
            ref="miModal"
        >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="miModalLabel">Editar Objetivo</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Cerrar"
                        @click="cerrarModal"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4" />
                        <div class="col-md-4 form-group">
                            <label><b>Ingrese el objetivo</b></label>
                            <input
                                v-model="newObjetivo.objetivo"
                                type="number"
                                placeholder="Ingrese el objetivo" class="form-control">
                        </div>
                        <div class="col-md-4" />
                    </div>

                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        @click="cerrarModal"
                    >
                        Cerrar
                    </button>
                    <button type="button" class="btn btn-primary" @click="saveObjetivo" v-if="showButtonSave">
                        Guardar cambios
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>
