<template>
    <div>
        <v-select :multiple="false" :options="optionsAnios" :placeholder="'Selecciona ' + placeholderm"
                  v-model="selectedAnios" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "anios",
    props: {
        placeholderm: String,
    },
    data() {
        // Obtenemos el año actual al crearse el componente
        const anioActual = new Date().getFullYear();

        return {
            // Inicializamos el año actual como el seleccionado por defecto
            selectedAnios: {
                label: anioActual.toString(), value: anioActual
            },
            // Creamos las opciones de años dinámicamente
            optionsAnios: this.generarOpcionesAnios(anioActual, 2021),
        };
    },
    methods: {
        // Método para generar los años hasta un límite
        generarOpcionesAnios(anioInicio, anioFin) {
            const opciones = [];
            for (let anio = anioInicio; anio >= anioFin; anio--) {
                opciones.push({label: anio.toString(), value: anio});
            }
            return opciones;
        },
        setCambio() {
            this.$emit('cambioAnio', this.selectedAnios);
        },
    },
    beforeMount() {
        //this.$emit('cambioAnio', this.selectedAnios);
    }
};
</script>

<style scoped>

</style>
