<template>
    <div>
        <v-select :multiple="false" :options="optionsWeeks" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedWeeks" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "semanaSelectKpi",
    props:{
        placeholderm: String,
    },
    data(){
        return{
            selectedWeeks: null,
            optionsWeeks:[],
            anioSelect: null,
        }
    },
    async mounted() {
        //await this.getDataWeeks();
        //let a = this.getWeekNumber(new Date())
        //this.setSelectWeek(a[1])
    },
    methods:{
        setSelectWeek(number){
            let r = this.optionsWeeks.findIndex((item) => item.value === number);
            r = this.optionsWeeks[r]
            this.selectedWeeks = r;
            this.setCambio()
        },
        async getDataWeeks(){ let $this = this;
            await axios
                .post('/api/Dashboard/getSemanasKpi', {
                    anio: this.anioSelect,
                })
                .then(function ({data}){
                    $this.optionsWeeks = data.Data;
                });
            let a = this.getWeekNumber(new Date())
            this.setSelectWeek(a[1])
        },
        setCambio(){
            this.$emit('cambioSemana');
        },
        getWeekNumber: (d) => {
            // Copy date so don't modify original
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            // Set to nearest Thursday: current date + 4 - current day number
            // Make Sunday's day number 7
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
            // Get first day of year
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
            // Calculate full weeks to nearest Thursday
            var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
            // Return array of year and week number
            return [d.getUTCFullYear(), weekNo];
        }
    }
}
</script>

<style scoped>

</style>
